<template>
    <section>
       <div class="content-wrapper">
           <div class="row">
               <h1 class="text-center">Mes cartes cadeaux offertes</h1>
               <div class="col-md-12">
                   <div v-if="isload" class="jumping-dots-loader">
                        <span></span>
                        <span></span>
                        <span></span>
                </div>
                   <b-table 
                    :items="items" 
                    id="table-list" 
                    responsive :per-page="perPage" 
                    :current-page="currentPage" 
                    :fields="fields" 
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                    :filter="search"
                    selectable
                    @row-clicked = "details"
                    >
                  <template #cell(client)="row">
                    <span class="pointer" @click="voucherDetails(row.item.ulid)">{{row.item.receiver.firstname}}</span>
                  </template>
                  <template #cell(type)>
                    <span>
                      <span class="badge badge-outline-default badge-pill"><i class="mdi mdi-calendar mr-2"></i>Carte cadeau</span>
                    </span>
                  </template>

                  <template #cell(date)="row">
                    <span>{{formatDate2(row.item.datepublished)}}</span>
                  </template>

                  <template #cell(status)>
                    <span class="badge badge-success">Succès</span>
                  </template>
                </b-table>

                <h3 v-if="items.length<1">Vous n'avez pas de carte cadeau</h3>
                <b-pagination
                v-else
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-list"
                  align="right">
                </b-pagination>
               </div>
           </div>
       </div>
    </section>
</template>
<script>
import axios from 'axios'
import { dateMixin } from './mixins/date'
export default {
    name:"myvoucher",
    mixins:[dateMixin],
    data(){
        return{
            perPage: 10,
            isload:true,
            sortBy: 'name',
            currentPage: 1,
            sortDesc: false,
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true,
            search:'',
            fields: [
            { key: 'client', label:'Bénéficiaire', sortable: true },
            { key: 'type', sortable: true },
            { key: 'date', sortable: true },
            { key: 'status', sortable: true },
            // { key: '#' }
            ],
            items: [
            ]
        }
    },
    computed: {
        rows() {
        return this.items.length
      }
    },
    methods: {
        details(record){
            this.voucherDetails(record.ulid)
        },
        getMyVouchers () {
            this.isload = true
            axios.get('myvoucher')
            .then(async resGetMyOrders => {
                this.items = resGetMyOrders.data.result
                console.log("liste voucher", this.items);

                
            this.isload = false

            })
            .catch(errGetMyOrders => console.log({errGetMyOrders}))
        },
        voucherDetails(ulid) {
            this.$router.push({
                name:"printedVoucher",
                params:{
                    ulid:ulid
                }
            })
            // this.$bvModal.show(ulid)
        },
        removeOrder(order){
            this.$swal({
                title: 'Désirez-vous annuler votre réservation?',
                showCancelButton: true,
                confirmButtonColor: '#0b0b0b',
                cancelButtonColor: '#fff',
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler'
            })
            .then((result) => {
                if (result.value) {
                this.isload =true
                axios.delete('myorders/'+order.item.ulid)
                    .then(()=>{
                    this.getMyOrders()
                    })
                    .catch()
                }else{
                    this.isload=false
                }
            })
        }
    },
    mounted () {
        this.getMyVouchers()
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('token');
    }
}
</script>